var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("mew-module", {
    staticClass: "d-flex flex-grow-1 pt-6 bgWalletBlock module-send",
    attrs: { title: "Send", "has-elevation": true, "has-indicator": true },
    scopedSlots: _vm._u([
      {
        key: "moduleBody",
        fn: function () {
          return [
            _c(
              "v-row",
              { staticClass: "mt-5" },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "pr-sm-1 pt-0 pb-0 pb-sm-4",
                    attrs: { cols: "12", sm: "6" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "position--relative" },
                      [
                        _c("app-button-balance", {
                          staticClass: "d-sm-none",
                          attrs: {
                            balance: _vm.selectedBalance,
                            loading: !_vm.showSelectedBalance,
                          },
                        }),
                        _c("mew-select", {
                          ref: "mewSelect",
                          staticStyle: { height: "62px" },
                          attrs: {
                            label: "Token",
                            items: _vm.tokens,
                            "is-custom": true,
                            value: _vm.selectedCurrency,
                          },
                          on: { input: _vm.setCurrency },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "pl-sm-1 pt-0 pb-2 pb-sm-4",
                    attrs: { cols: "12", sm: "6" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "position--relative" },
                      [
                        _c("app-button-balance", {
                          staticClass: "d-none d-sm-block",
                          attrs: {
                            balance: _vm.selectedBalance,
                            loading: !_vm.showSelectedBalance,
                          },
                        }),
                        _c("mew-input", {
                          staticClass: "AmountInput",
                          attrs: {
                            label: "Amount",
                            value: _vm.amount,
                            type: "number",
                            placeholder: "0",
                            "persistent-hint": true,
                            "error-messages": _vm.amountErrorMessage,
                            "max-btn-obj": {
                              title: "Max",
                              disabled: _vm.disableSwapBtn,
                              method: _vm.setEntireBal,
                            },
                            "buy-more-str": _vm.buyMoreStr,
                          },
                          on: {
                            buyMore: _vm.openBuySell,
                            input: function (val) {
                              return _vm.setAmount(val, false)
                            },
                          },
                          nativeOn: {
                            keydown: function ($event) {
                              return _vm.preventCharE($event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm.showBalanceNotice
                  ? _c(
                      "v-col",
                      { staticClass: "pt-0 pb-4", attrs: { cols: "12" } },
                      [
                        _c("send-low-balance-notice", {
                          staticClass: "pa-3",
                          attrs: {
                            address: _vm.address,
                            "currency-name": _vm.currencyName,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-col",
                  { staticClass: "pt-4 pb-2", attrs: { cols: "12" } },
                  [
                    _c("module-address-book", {
                      ref: "addressInput",
                      staticClass: "AddressInput",
                      attrs: { currency: _vm.currencyName },
                      on: { setAddress: _vm.setAddress },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "py-0 mb-8", attrs: { cols: "12" } },
                  [
                    _c("app-transaction-fee", {
                      attrs: {
                        "show-fee": _vm.showSelectedBalance,
                        "getting-fee": !_vm.txFeeIsReady,
                        error: _vm.feeError,
                        "total-cost": _vm.totalCost,
                        "tx-fee": _vm.txFee,
                        "total-gas-limit": _vm.gasLimit,
                        message: _vm.feeError,
                        "not-enough-eth": !_vm.hasEnoughEth,
                        "from-eth": _vm.isFromNetworkCurrency,
                      },
                      on: { onLocalGasPrice: _vm.handleLocalGasPrice },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "py-4", attrs: { cols: "12" } },
                  [
                    _c("mew-expand-panel", {
                      ref: "expandPanel",
                      attrs: {
                        "panel-items": _vm.expandPanel,
                        "idx-to-expand": _vm.openedPanels,
                      },
                      on: { toggled: _vm.closeToggle },
                      scopedSlots: _vm._u([
                        {
                          key: "panelBody1",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "px-5" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pa-5 warning greyPrimary--text border-radius--5px mb-8",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex font-weight-bold mb-2 textDark--text",
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "textDark--text mew-body mr-1",
                                            },
                                            [_vm._v(" mdi-alert-outline")]
                                          ),
                                          _vm._v("For advanced users only "),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "textDark--text" },
                                        [
                                          _vm._v(
                                            " Please don't edit these fields unless you are an expert user & know what you're doing. Entering the wrong information could result in your transaction failing or getting stuck. "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-end pb-3",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mew-body greenPrimary--text cursor--pointer",
                                          on: {
                                            click: function ($event) {
                                              return _vm.setGasLimit(
                                                _vm.defaultGasLimit
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " Reset to default: " +
                                              _vm._s(
                                                _vm.formattedDefaultGasLimit
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("mew-input", {
                                    attrs: {
                                      value: _vm.gasLimit,
                                      label: _vm.$t("common.gas.limit"),
                                      placeholder: "",
                                      "error-messages": _vm.gasLimitError,
                                      type: "number",
                                    },
                                    on: { input: _vm.setGasLimit },
                                  }),
                                  _c("mew-input", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.isToken,
                                        expression: "!isToken",
                                      },
                                    ],
                                    ref: "dataInput",
                                    staticClass: "mb-8",
                                    attrs: {
                                      label: _vm.$t("sendTx.add-data"),
                                      placeholder: "0x...",
                                      rules: _vm.dataRules,
                                      "error-messages":
                                        _vm.dataInvalidHexMessage,
                                      "hide-clear-btn": _vm.data === "0x",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        return _vm.verifyHexFormat.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      focusout: function ($event) {
                                        return _vm.verifyHexFormat.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.data,
                                      callback: function ($$v) {
                                        _vm.data = $$v
                                      },
                                      expression: "data",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "d-flex flex-column mt-12" }, [
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("mew-button", {
                    staticClass: "SendButton",
                    attrs: {
                      title: "Next",
                      "has-full-width": false,
                      "btn-size": "xlarge",
                      disabled: _vm.isDisabledNextBtn,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.send()
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "text-center mt-4" },
                [
                  _c("mew-button", {
                    attrs: {
                      title: _vm.$t("common.clear-all"),
                      "has-full-width": false,
                      "btn-size": "small",
                      "btn-style": "transparent",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.clear()
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }